import {isEmpty} from "lodash";
import axios from "axios";
import {
    callApiToUpdateIndicativeTerms,
    fetchCollectionData,
    fetchTaggedDocs,
    fetchTaggedDropdown,
    getCibilScore,
    refetchCibilScore,
    sendWaitlistedEmail,
    updateDocTags,
    fetchGlobalComplianceData,
    callApiToUpdateTotRecurFee,
    fetchBankAlerts,
    getUwFlag,
    updateLanId,
    getCustomerQueries,
    fetchTdsClaim,
    fetchPresignedUrlMnemosyne,
    callUpdateClaim,
    callSendReminderToInv,
    refetchTdsData,
    checkStatusBeforeCompanyEdit,
    triggerSwiftEmailToUser,
    downloadComplianceFilesRequest,
    fetchBalanceConfirmationData,
    sendReminderToInvCustomerSupport,
    getSupportQueryApi,
    fetchSignedUrlToUpload,
    saveMetaDataInDocService,
    callApiToDeleteFileFromDocService,
    deleteFileApi,
    callUpdateSupportQuery,
    editNachRequest,
    fetchOrgNachDetailsRequest,
    createNach,
    fetchMoratMonths,
    fetchAdhocNachData,
    callCreateAdhocNach,
    callEditAdhocNach,
    callDeleteAdhocNach,
    refetchKarzaData,
    fetchKarzaFetchedStatus, 
    getRecordedPayments, 
    verifyRecordedPayment,
    getLastFetchedDetailsRequest,
    expireApplicationRequest,
    verifyRecordedPaymentBulk,
    fetchMfaSlUploadsInvestors,
    toggleBankAccountStatus,
    fetchBankAccountList,
    downloadTdsTransferZip,
    approveVerifiedTdsClaims,
    callAddDealComments,
    fetchIsComplianceStatusNotMet,
    getCompaniesLegalNamesListService,
} from "./ApiContainer.ts";
import {showNotification} from "../util/utility.js";
import moment from "moment";
import {API_STATUS, API_STATUS_CODE, GENERIC_ERROR, SHOW_NOTIFICATION_STATUS} from "../constants/enums.js";
import {callApiToUpdateNachAccount, regeneratePingReport, callApiToGetRecoveryViewDetails, callApiToUpdateRecoveryViewOwner, callApiToCreateRecoveryViewComments } from "./ApiContainer";
import {UpdateClaimPayload, CreateNachPayload, EditFacilityNachPayload} from "./ApiTypes";
import { JAVA_API_STATUS } from "../constants/commonConstants.js";

let source: any = null;

export const CERBERUS_STATUS_CODES = {
    SUCCESS: 20,
    FAILURE: 40,
};


export const getTaggedDocs = async (data: any): Promise<any> => {
    try {
        if (isEmpty(data)) return null;
        const response = await fetchTaggedDocs(data);
        if (response?.data?.responseData?.responseCode === CERBERUS_STATUS_CODES.SUCCESS)
            return response?.data;
        return response?.data?.responseData;
    } catch (err: any) {
        if (err?.response?.data) return err?.response?.data;
    }
};
export const getTaggedDropdown = async (data: any): Promise<any> => {
    try {
        if (isEmpty(data)) return null;
        const response = await fetchTaggedDropdown(data);
        if (response?.data?.responseData?.responseCode === CERBERUS_STATUS_CODES.SUCCESS)
            return response?.data;
        return response?.data?.responseData;
    } catch (err: any) {
        if (err?.response?.data) return err?.response?.data;
    }
};
export const getCibilScoreHelper = async (data: any): Promise<any> => {
    try {
        if (isEmpty(data)) return null;
        const response = await getCibilScore(data);
        if (response?.data)
            return response?.data;
        return null
    } catch (err: any) {
        if (err?.response?.data) return err?.response?.data;
    }
};
export const refetchCibilScoreHelper = async (data: any): Promise<any> => {
    try {
        if (isEmpty(data)) return null;
        const response = await refetchCibilScore(data);
        if (response?.data)
            return response?.data;
        return null
    } catch (err: any) {
        if (err?.response?.data) return err?.response?.data;
    }
};

export const refetchKarzaDataHelper = async (data: any): Promise<any> => {
    try {
        if (isEmpty(data)) return null;
        const response = await refetchKarzaData(data);
        if (response?.data)
            return response?.data;
        return null
    } catch (err: any) {
        if (err?.response?.data) return err?.response?.data;
    }
};
export const fetchKarzaFetchedStatusHelper = async (data: any): Promise<any> => {
    try {
        if (isEmpty(data)) return null;
        const response = await fetchKarzaFetchedStatus(data);
        if (response?.data)
            return response?.data;
        return null
    } catch (err: any) {
        if (err?.response?.data) return err?.response?.data;
    }
};
export const fetchMfaSlUploadsInvestorsHelper = async (data: any): Promise<any> => {
    try {
        if (isEmpty(data)) {
            return null;
        }
        const response = await fetchMfaSlUploadsInvestors(data);
        if (response?.data) {
            return response?.data;
        }
        return {}
    } catch (err: any) {
        if (err?.response?.data) { 
            return err?.response?.data;
        }
    }
};
export const toggleBankAccountStatusHelper = async (data: any): Promise<any> => {
    try {
        if (isEmpty(data)) {
            return null;
        }
        const response = await toggleBankAccountStatus(data);
        if (response?.data) {
            return response?.data;
        }
        return {}
    } catch (err: any) {
        if (err?.response?.data) { 
            return err?.response?.data;
        }
    }
};
export const fetchBankAccountListHelper = async (data: any): Promise<any> => {
    try {
        if (isEmpty(data)) {
            return null;
        }
        const response = await fetchBankAccountList(data);
        if (response?.data) {
            return response?.data;
        }
        return {}
    } catch (err: any) {
        if (err?.response?.data) { 
            return err?.response?.data;
        }
    }
};
export const getUpdateDocTags = async (data: any): Promise<any> => {
    try {
        if (isEmpty(data)) return null;
        const response = await updateDocTags(data);
        if (response?.data?.responseData?.responseCode === CERBERUS_STATUS_CODES.SUCCESS)
            return response?.data;
        return response?.data?.responseData;
    } catch (err: any) {
        if (err?.response?.data) return err?.response?.data;
    }
};
export const sendWaitlistedEmailHelper = async (orgId: string, identifierType: string, identifierId: string): Promise<any> => {
    try {
        const response = await sendWaitlistedEmail(orgId, identifierType, identifierId);
        if (response?.data)
            return response?.data;
    } catch (err: any) {
        if (err?.response?.data) return err?.response?.data;
    }
}

export const fetchIsComplianceStatusNotMetHelper = async (data: any): Promise<any> => {
    try {
        if (isEmpty(data)) return null;
        const response = await fetchIsComplianceStatusNotMet(data);
        if (response?.data)
            return response?.data;
        return null
    } catch (err: any) {
        if (err?.response?.data) return err?.response?.data;
    }
}

export const fetchComplianceData = async (data: any, setLoading: (loading: boolean) => void): Promise<any> => {
    setLoading(true)
    if (source !== null) {
        source.cancel("Cancelling token due to new API call");
    }
    source = axios.CancelToken.source();
    data.cancelToken = source?.token;
    try {
        const response = await fetchGlobalComplianceData(data);
        source = null;
        setLoading(false);
        if (response?.data)
            return response?.data?.complianceResponse;
        throw new Error("Something went wrong");
    } catch (err: any) {
        if (err?.response?.data) return err?.response?.data;
    }
}
export const getCollectionDetails = async (data: any, beta: boolean): Promise<any> => {
    try {
        if (isEmpty(data)) return null;
        const response = await fetchCollectionData(data, beta);
        if (beta) {
            if (response?.data?.responseData?.responseCode === CERBERUS_STATUS_CODES.SUCCESS)
                return response?.data;
        } else {
            return response?.data;
        }
        return false;
    } catch (err: any) {
        if (err?.response?.data) return false;
    }
}

// Fetching Bank ALerts for Summary Screen
export const getBankAlerts = async (orgId: string): Promise<any> => {
    try {
        const response = await fetchBankAlerts(orgId);
        if (response?.data?.statusCode === 200) {
            return response?.data?.data?.data;
        } else {
            return [{
                primaryKey: "No Alerts Found",
                secondaryKey: moment().utc().format("DD MMM YYY")
            }]
        }

    } catch (err: any) {
        if (err?.response?.data) return [{
            primaryKey: "No Alerts Found",
            secondaryKey: moment().utc().format("DD MMM YYY")
        }];
    }
}
export const updateIndicativeTerms = async ({
                                                data,
                                            }: {
    data: {
        tentativeDealSize: number;
        tentativeTenure: number;
        rateOfInterest: number;
        tentativeFinancingStructure: string[];
        securityOthersComments: string;
        investorProcessingFee: number,
        termsLastReviewedBy: string,
        offerId?: string;
        auditHandlerReqDto: {
            requestIdForAudit: string,
            auditData: {
                changeOwner: string,
                orgId: string,
                existingState: object,
                newState: object,
                requestUri: string,
                changeDate: object,
            }
        }
    };
}): Promise<Boolean> => {
    try {
        const res = await callApiToUpdateIndicativeTerms(data);
        if (res.status === 200 && res.data?.responseData?.responseCode === 40) {
            const message = res?.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            return false;
        } else if (res.status === 200 && res.data?.responseData?.responseCode === 20) {
            if (data.hasOwnProperty("sharedIpaWithCompany")) {
                showNotification(SHOW_NOTIFICATION_STATUS.SUCCESS, 'IPA Shared with Company Successfully');
            } else
                showNotification(SHOW_NOTIFICATION_STATUS.SUCCESS, 'Indicative Terms Updated');
            return true;
        } else {
            const message = res?.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            return false;
        }
        return true;
    } catch (err) {
        return false;
    }
};

export const totRecurFeeUpdate = async ({
                                            data,
                                        }: {
    data: {
        totId: string,
        recurAdvisoryFee: number,
        recurAdvisoryFeeType: boolean,
        requestId: string,
        auditHandlerReqDto: {
            requestIdForAudit: string,
            auditData: {
                changeOwner: string,
                orgId: string,
                existingState: object,
                newState: object,
                requestUri: string,
                changeDate: object,
            }
        }

    };
}): Promise<Boolean> => {
    try {
        const res = await callApiToUpdateTotRecurFee(data);
        if (res.status === 200 && res.data?.responseData?.responseCode === 40) {
            const message = res?.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            return false;
        } else if (res.status === 200 && res.data?.responseData?.responseCode === 20) {
            showNotification(SHOW_NOTIFICATION_STATUS.SUCCESS, 'Recur Fee Updated');
            return true;
        } else {
            const message = res?.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            return false;
        }
        return true;
    } catch (err) {
        return false;
    }
};

export const getUwRiskFlag = async (orgId: string, identifierId: string | undefined, requestId: string): Promise<any> => {
    try {
        const response = await getUwFlag(orgId, identifierId, requestId);
        if (response?.status == 200) {
            return response?.data;
        } else {
            return {}
        }

    } catch (err: any) {
        
            if (err?.response?.data) return {}
        
        ;
    }

}
export const updateLanID = async (orderData: { order_id: string, lan_id: string }): Promise<any> => {
    try {
        const {order_id, lan_id} = orderData
        if (order_id === null || lan_id === null) {
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, "Incorrect data");
        }
        const dataToSend = {
            lan_id: lan_id,
            order_id: order_id
        }
        const response = await updateLanId(dataToSend);
        if (response?.status === API_STATUS_CODE.SUCCESS) {
            showNotification(SHOW_NOTIFICATION_STATUS.SUCCESS, "Lan Id Updated");
            return response?.status;
        }

        return response?.data?.responseData;
    } catch (err: any) {
        if (err?.response?.data) return err?.response?.data;
    }
};

export const pingReportRegenerate = async (investeeOrganizationId: string): Promise<any> => {
    try {
        if (investeeOrganizationId === null) {
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, "Incorrect data");
        }
        const response = await regeneratePingReport(investeeOrganizationId);
        if (response?.status === API_STATUS_CODE.SUCCESS) {
            showNotification(SHOW_NOTIFICATION_STATUS.SUCCESS, "Ping Report Regenerated");
            return response?.data;
        }

        return response?.data?.responseData;
    } catch (err: any) {
        if (err?.response?.data) return err?.response?.data;
    }
};
export const fetchCustomerQueries = async (payload: object): Promise<any> => {
    try {
        const res = await getCustomerQueries(payload);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        return false;
    }
};
export const getClaim = async (customerQueryId: string | null): Promise<any> => {
    try {
        const res = await fetchTdsClaim(customerQueryId);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        return false;
    }
};
export const sendTdsReminderToInv = async (customerSupportQueryId: string, email: string): Promise<any> => {
    try {
        const res = await callSendReminderToInv(customerSupportQueryId, email);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        return false;
    }
};

export const sendReminderToCustomerSupport = async (customerSupportQueryId: string, email: string): Promise<any> => {
    try {
        const res = await sendReminderToInvCustomerSupport(customerSupportQueryId, email);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        return false;
    }
};

export const fetchPresignedUrl = async (docId: string | Number | undefined): Promise<any> => {
    try {
        const res = await fetchPresignedUrlMnemosyne(docId);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 'OK') {
                return res.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (e) {
        return false;
    }
}

export const getSignedUrlToUpload = async ({
                                               input,
                                               orgId,
                                           }: {
    input: object;
    orgId: string;
}): Promise<any> => {
    try {
        const res = await fetchSignedUrlToUpload(input, orgId);
        if (res?.data?.responseData?.responseCode === 'OK') return res.data;
        else {
            const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            return false;
        }
    } catch (err) {
        console.error(err);
    }
};

export const saveDocMetaDataInDocService = async ({
                                                      input,
                                                      orgId,
                                                  }: {
    input: object;
    orgId: string;
}): Promise<any> => {
    try {
        const res = await saveMetaDataInDocService(input, orgId);
        if (res?.data?.responseData?.responseCode === 'OK')
            return res?.data?.fileMetadata;
        else {
            const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            return false;
        }
    } catch (err) {
        console.error(err);
    }
};

export const deleteFileFromDocService = async ({
                                                   docId,
                                                   onSuccess,
                                               }: {
    docId: number;
    onSuccess: Function;
}): Promise<any> => {
    try {
        const res = await callApiToDeleteFileFromDocService(docId);
        if (res?.data?.responseData?.responseCode === 'OK') onSuccess();
        throw new Error(res?.data?.responseData?.responseMessage ?? GENERIC_ERROR);
    } catch (err) {
        console.error(err);
    }
};

export const deleteFile = async (input: any, data: any, isOnboarding: boolean) => {
    try {
        const response = await deleteFileApi(input, data, isOnboarding);
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

export const updateClaim = async (data: UpdateClaimPayload): Promise<any> => {
    try {
        const res = await callUpdateClaim(data);
        if (res.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return true;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        return false;
    }
};

export const updateSupportQuery = async (data: any): Promise<any> => {
    try {
        const res = await callUpdateSupportQuery(data);
        if (res.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return true;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        return false;
    }
};

export const getRefetchTdsClaimsData = async () => {
    try {
        const res = await refetchTdsData();
        if (res.status === 200) {
            if (res.data.responseData.responseCode === 20) {
                return true;
            }
        }
    } catch (err) {
        return false;
    }
}

export const getStatusBeforeCompanyEdit = async (investeeOrgId: string, payload: any) => {
    try {
        const res = await checkStatusBeforeCompanyEdit(investeeOrgId, payload);
        if (res.status === 200 && res.data.status === API_STATUS.SUCCESS) {
            return res.data.data?.investeeorganization;
        }
    } catch (err) {
        return null;
    }
}

export const emailSwiftTermsToUser = async (totId: string, payload: any): Promise<boolean> => {
    try {
        const res = await triggerSwiftEmailToUser(totId, payload);
        if (res.status === 200 && res.data.status === API_STATUS.SUCCESS) {
            return true;
        }
        const message = res.data?.message ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
    } catch (err: any) {
        const message = err.response?.data?.data?.message ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        console.error("Exception caught while sending email for swift terms", err);
    }
    return false;
};

export const downloadComplianceFiles = async (cpId: string) => {
    try {
        const res = await downloadComplianceFilesRequest(cpId);
        if (res.status === 200) {
            return res.data;
        }
        const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
    } catch (err) {
        console.error(err);
    }
    return null;
};

export const getBalanceConfirmationData = async (data: string): Promise<any> => {
    try {
        const res = await fetchBalanceConfirmationData(data);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        return false;
    }
};

export const getSupportQueryData = async (data: string): Promise<any> => {
    try {
        const res = await getSupportQueryApi(data);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        return false;
    }
};

export const createFacilityNach = async (data: CreateNachPayload): Promise<any> => {
    try {
        const res = await createNach(data);

        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        return false;
    }
}

export const fetchOrgNachDetails = async (orgId: string): Promise<any> => {
    try {
        const res = await fetchOrgNachDetailsRequest(orgId);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        return false;
    }
}

export const editNach = async (data: EditFacilityNachPayload): Promise<any> => {
    try {
        const res = await editNachRequest(data);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        return false;
    }
}
export const getMoratMonths = async (noOfMonths: number[]): Promise<any> => {
    try {
        const res = await fetchMoratMonths(noOfMonths);
        if (res?.status === 200) {
            return res.data?.data;
        } else {
            const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        return false;
    }
}

export const fetchRecordedPayments = async (payload: object): Promise<any> => {
    try {
        const res = await getRecordedPayments(payload);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        return false;
    }
};

export const markRecordedPayment = async (payload: object): Promise<any> => {
    try {
        const res = await verifyRecordedPayment(payload);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return true;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        return false;
    }
};

export const markRecordedPaymentBulk = async (payload: object): Promise<any> => {
    try {
        const res = await verifyRecordedPaymentBulk(payload);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return {};
            }
        }
    } catch (err) {
        return false;
    }
};

export const getLastFetchedDetails = async (investeeOrgId: string): Promise<any> => {
    try {
        const res = await getLastFetchedDetailsRequest(investeeOrgId);
        if (res?.status === 200) {
            if (res.data?.statusCode === 200) {
                return res.data?.data;
            } else {
                const message = res.data?.message ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        return false;
    }
}

export const expireApplication = async (payload: object): Promise<any> => {
    try {
        const res = await expireApplicationRequest(payload);        
        return res?.data; 
    } catch (err: any) {
        return false;
    }
}

export const getAdhocNachDetails = async (data:any): Promise<any> => {
    try {
        const res = await fetchAdhocNachData(data);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        return false;
    }
}

export const getCreateNach= async (data:any): Promise<any> => {
    try {
        const res = await callCreateAdhocNach(data);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    }catch (err) {
        return false;
    }
}

export const editNachDetails = async (data:any): Promise<any> => {
    try {
        const res = await callEditAdhocNach(data);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    }catch (err) {
        return false;
    }
}

export const cancelNachRequest = async (data:any) => {
    try {
        const res = await callDeleteAdhocNach(data);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    }catch (err) {
        return false;
    }
}

export const downloadTdsTransferZipFile = async (data: any): Promise<any> => {
    try {
        const res = await downloadTdsTransferZip({});
        if (res.status === 200) {
            return res.data;
        }
        showNotification(
            SHOW_NOTIFICATION_STATUS.ERROR,
            'Unable to download file. Please try again later.',
        );
    } catch (e) {
        return false;
    }
};

export const approveBulkTdsClaims = async (data:any) => {
    try {
        const res = await approveVerifiedTdsClaims(data);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return true;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    }catch (err) {
        return false;
    }
}

export const updateNachAccount = async (data: any): Promise<any> => {
    try {
        const response = await callApiToUpdateNachAccount(data);
        if (response.status === 200) {
            return response.data?.data;
        }
        const message = response.data?.responseData?.responseMessage ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
    } catch (err) {
        const message = GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
    }
    return false;
};

export const addDealComments = async (data:any) => {
    try {
        const res = await callAddDealComments(data);
        if (res?.status === 200) {
            if (res.data?.responseData?.internalResponseCode === 20) {
                return true;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    }catch (err) {
        return false;
    }
}
export const getRecoveryViewDetails = async (data: any): Promise<any> => {
    try {
        const response = await callApiToGetRecoveryViewDetails(data);
        if (response.status === 200) {
            return response.data?.data;
        }
        const message = response.data?.responseData?.responseMessage ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
    } catch (err) {
        const message = GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
    }
    return false;
};

export const updateRecoveryViewOwner = async (data: any): Promise<any> => {
    try {
        const response = await callApiToUpdateRecoveryViewOwner(data);
        if (response.status === 200) {
            return response.data?.data;
        }
        const message = response.data?.responseData?.responseMessage ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
    } catch (err) {
        const message = GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
    }
    return false;
};
export const createRecoveryViewComments = async (data: any): Promise<any> => {
    try {
        const response = await callApiToCreateRecoveryViewComments(data);
        if (response.status === 200) {
            return response.data?.data;
        }
        const message = response.data?.responseData?.responseMessage ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
    } catch (err) {
        const message = GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
    }
    return false;
};
export async function getCompaniesLegalNamesList(payload: any, handlers: any) {
    try {
        const response = await getCompaniesLegalNamesListService(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess?.(response?.data?.autocompleteResultList || []);
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                handlers?.onError?.();
                break;
        }
    } catch (error) {
        handlers?.onError?.();
        showNotification(
            SHOW_NOTIFICATION_STATUS.ERROR,
            'Error fetching application details config',
        );
    }
}
