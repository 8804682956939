import { Modal } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import React from "react";

export interface NeftCancellationModalProps {
  show: boolean;
  setShowModal: (showModal: boolean) => void;
  placeHolderText: string;
  setRejectionReason: (rejectionReason: string) => void;
  rejectionReason: string;
  handleRejectionConfirmed: () => void;
}

export const NeftCancellationModal = ({
  show,
  setShowModal,
  placeHolderText,
  setRejectionReason,
  rejectionReason,
  handleRejectionConfirmed,
}: NeftCancellationModalProps) => {

  const handleKeepNeft = () => {
    setRejectionReason('');
    setShowModal(false);
  }

  const handleCancelNeft = () => {
    handleRejectionConfirmed();
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length <= 500) {
      setRejectionReason(e.target.value);
    }
  };

  return (
    <Modal show={show} onHide={() => setShowModal(false)}>
      <Modal.Body role="dialog" aria-label="NEFT Transaction Rejection">
        <div className="d-flex justify-content-between mt-2">
          <div className={`fw600 mt-1`} role="heading" aria-level="2">
            Are you sure you want to reject the transaction?
          </div>
          <div className="closeNeftModal">
            <img
              src="/assets/modal-cross.svg"
              alt="Close modal"
              onClick={() => setShowModal(false)}
              role="button"
              aria-label="Close modal"
            />
          </div>
        </div>
        <div className="textarea-container">
          <textarea
            className="mt-4 form-control rejection-textarea"
            value={rejectionReason}
            onChange={handleInputChange}
            placeholder={placeHolderText}
            maxLength={500}
            data-tip={rejectionReason}
            data-for="rejectionTooltip"
            aria-label="Rejection reason"
            aria-required="true"
          />
          <ReactTooltip
            id="rejectionTooltip"
            place="bottom"
            type="light"
            effect="solid"
            delayShow={500}
            className="tooltip"
          />
        </div>
        <div className="d-flex justify-content-between neftModalButtons">
          <button className="keepNeft border-0 rounded"
            onClick={handleKeepNeft}
            aria-label="Cancel rejection"
          >
            Cancel
          </button>
          <button className="cancelNeft border-0 rounded"
            onClick={handleCancelNeft} 
            disabled={!rejectionReason}
            aria-label="Confirm rejection"
          >
            Reject
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};