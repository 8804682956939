import React, { useState, useEffect, useRef } from "react";
import "./CompanyNameDropdown.Module.scss";
import { getCompaniesLegalNamesList } from "../../../../../common/ApiHelpers";
import blackCircle from "../../../../../assets/plus-black-circle.svg";

const CompanyNameDropdown = ({ orgName, cin, setOrgName, setCin }) => {
  const [companies, setCompanies] = useState([]); // company list
  const [searchInput, setSearchInput] = useState("");
  const [debouncedInput, setDebouncedInput] = useState(searchInput);
  const [showDropdown, setShowDropdown] = useState(false); // Dropdown visibility
  const [isLoading, setIsLoading] = useState(false); // Loading state for API call
  const dropdownRef = useRef(null); // Reference to the dropdown for handling clicks outside

  const fetchCompaniesList = (searchTerm = "") => {
    setIsLoading(true);
    getCompaniesLegalNamesList(
      { queryString: searchTerm },
      {
        onSuccess: (data) => {
          setCompanies(data);
          setShowDropdown(true);
          setIsLoading(false);
        },
        onError: () => {
          setIsLoading(false);
        },
      }
    );
  };

  // Handle input change for searching
  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
    setOrgName(event.target.value);
    setCin(null);
  };

  // Handle clicks outside the dropdown to close it
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  // Handle selection from the dropdown
  const handleSelectCompany = (company) => {
    setOrgName(company.name); // Set input value to selected company
    setCin(company.identifier); // Set CIN value to selected company
    setShowDropdown(false); // Close the dropdown
  };

  // Fetch companies based on debounced input
  useEffect(() => {
    if (debouncedInput?.length > 0 && debouncedInput?.trim()?.length > 2) {
      setIsLoading(true);
      fetchCompaniesList(debouncedInput.trim());
    } else {
      setShowDropdown(false);
      setCompanies([]); // Clear list if there's no input
    }
  }, [debouncedInput]);

  // Debouncing effect
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedInput(searchInput); // Update debouncedInput after delay
    }, 300); // 300ms debounce delay

    // Cleanup function to clear timeout if user types within the delay
    return () => {
      clearTimeout(handler);
    };
  }, [searchInput]);

  return (
    <div
      className="input-group"
      ref={dropdownRef}
      style={{ position: "relative" }}
    >
      <input
        value={orgName}
        required
        onChange={(e) => {
          handleInputChange(e);
        }}
        className="admin-form-field mt-3"
        style={{ color: "black" }}
        type="text" // Use text type to allow for company names
        placeholder="Enter Company Legal Name or PAN"
      />

      {showDropdown && (
        <ul
          className="dropdown-menu"
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            width: "100%",
            maxHeight: "200px",
            overflowY: "auto",
          }}
        >
          {isLoading ? (
            <li>Loading...</li>
          ) : companies.length > 0 ? (
            companies.map((company) => (
              <div
                className="dropdown-item"
                onClick={() => handleSelectCompany(company)}
                key={company.identifier}
              >
                <div className="Left">{company.name}</div>
                <div className="Right">{company.identifier}</div>
              </div>
            ))
          ) : (
            <div className="no-results">No results found.</div>
          )}
          {
            <div
              className="AddNew"
              onClick={() => {
                setShowDropdown(false);
                setCompanies([]);
              }}
            >
              <img src={blackCircle} alt="" height={"15px"} />
              <span style={{ marginLeft: "0.5rem" }}>
                Add new organisation "{orgName}"
              </span>
            </div>
          }
        </ul>
      )}
    </div>
  );
};

export default CompanyNameDropdown;
